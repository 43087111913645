import React from 'react';

import { PdlLogos } from '.';
import { LOGOS } from '../pdlContent';

const PdlLogoBlock = () => {
  return (
    <div className='pdl-logo-block'>
      <h2 className='pdl-logo-block__title'>Some of the brands enjoying our art</h2>
      <PdlLogos logos={LOGOS} />
    </div>
  );
};

export default PdlLogoBlock;
