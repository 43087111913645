const lg = require('/app/assets/images/logos/LG 1.svg') as string;
const marriott = require('/app/assets/images/logos/Marriott 1.svg') as string;
const samsung = require('/app/assets/images/logos/Samsung 1.svg') as string;
const jimmyChoo = require('/app/assets/images/logos/Jimmy Choo 1.svg') as string;
const marmara = require('/app/assets/images/logos/Marmara 1.svg') as string;
const citizenM = require('/app/assets/images/logos/Citizen M.svg') as string;
const bxp = require('/app/assets/images/logos/Boston Properties 1.svg') as string;
const qt = require('/app/assets/images/logos/QT.svg') as string;
const hyundai = require('/app/assets/images/logos/hyundai 2.svg') as string;
const pam = require('/app/assets/images/logos/Public Art Management 1.svg') as string;

export const LOGOS = [
  {
    name: 'LG',
    logo: lg,
    url: 'https://www.lg.com/',
  },
  {
    name: 'Marriott',
    logo: marriott,
    url: 'https://www.marriott.com/',
  },
  {
    name: 'Samsung',
    logo: samsung,
    url: 'https://www.samsung.com/',
  },
  {
    name: 'Jimmy Choo',
    logo: jimmyChoo,
    url: 'https://www.jimmychoo.com/',
    size: 'tiny',
  },
  {
    name: 'The Marmara Hotels & Residences',
    logo: marmara,
    url: 'https://www.themarmarahotels.com/',
  },
  {
    name: 'Citizen M',
    logo: citizenM,
    url: 'https://www.citizenm.com/',
  },
  {
    name: 'Boston Properties',
    logo: bxp,
    url: 'https://www.bxp.com/',
    size: 'small',
  },
  {
    name: 'QT Hotels & Resorts',
    logo: qt,
    url: 'https://www.qthotels.com/',
  },
  {
    name: 'Hyundai',
    logo: hyundai,
    url: 'https://www.hyundai.com/worldwide/',
  },
  {
    name: 'Public Art Management',
    logo: pam,
    url: 'https://www.publicartmgmt.com/',
  },
] as { name: string; logo: string; url: string; size?: 'small' | 'tiny' }[];

export const PDL_BLOCKS = [
    {
      fileName: 'pdl-hotel.mp4',
      smallHeading: 'Digital art for hotels',
      heading: 'Brighten up guest rooms with artworks',
      text: 'Curate stunning collections to capture the imagination of your guests.',
    },
    {
      fileName: 'pdl-cigar-room.mp4',
      smallHeading: 'Digital art for offices, meeting rooms and lobbies',
      heading: 'Beautify any public space or event',
      text: 'Make hours feel like minutes with a captivating artwork display.',
    },
    {
      fileName: 'pdl-green.mp4',
      smallHeading: 'Digital art for lounges and clubs',
      heading: 'Create an exclusive atmosphere',
      text: 'Give your audience an exquisite visual experience they won’t forget.',
    },
  ] as {fileName: string, smallHeading: string, heading: string, text: string}[];

  export const HERO_TEXT = [
    'Guest Rooms',
    'Lounges',
    'Offices',
    'Meeting Rooms',
    'Public Spaces',
    'Business',
  ] as string[];
  
  export const CTA_CONTENT = {
    title: 'Bring art to your business.',
    text: "Tell us about your venue, your screens and your audience.\nWe'll curate a selection of artworks best suited for you.",
    btnText: 'Book a call',
    btnLink: 'https://calendly.com/vlad_sedition/30min',
  } as {title: string, text: string, btnText: string, btnLink: string};

  export const PDL_FAQS = [
    {
      title: 'Can I use the art available on Sedition in a public or a commercial setting?',
      content: `
      <p>Yes, you can.</p>
      <p>However the prices listed on the site are for private collectors only - for public usage you will require a PDL - a public display licence. We’d be delighted to help you in setting up your display - <a href='https://calendly.com/vlad_sedition/30min' class="pdl-faqs__link">contact us here</a>!</p>
      <p>Learn about public art displays with Sedition with our Director Rory Blain here:</p>
      <iframe class="pdl-faqs__video" frameborder="0" allowfullscreen="true" src="https://player.vimeo.com/video/735693296/"></iframe>
      `,
    },
    {
      title: 'I bought an artwork from the Sedition website, can I display it publicly?',
      content: `
      <p>No, unless you have arranged a PDL (Public Display Licence) through us, then buying an edition of an artwork from Sedition’s website does not give you public display rights.</p>
      <p>Public displays are always a bespoke arrangement between Sedition, the display partner and the artist, and are subject to different pricing than shown on the website.</p>
      `,
    },
    {
      title:
        'I bought an Art Stream subscription from the Sedition website, can I play artworks publicly?',
      content: `
      <p>No, subscribing to Art Stream does not give you public display rights.</p>
      <p>Public displays are always a bespoke arrangement and are subject to different pricing.</p>
      <p>It is currently not possible to licence Art Stream for a public display usage, however, we intend to launch a corporate version of Art Stream that will include the licences for display in the yearly or monthly fee. More announcements coming soon.</p>
      `,
    },
    {
      title: 'What is a Public Display Licence?',
      content: `
      <p>A public display licence is a document granting permissions and display rights and outlining the terms of the agreement - such as fee, duration, location and content of artists and works. PDLs are issued to display an artwork from Sedition in a public space or an exhibition.</p>
      <p>Any public display of an artwork requires a licence from Sedition, which gives you the rights to present the work and provides protection for the use of the work.</p>
      <p>Public Display Licences are subject to a fee that is negotiated on a case by case basis for each client in collaboration with the selected artists.</p>
      `,
    },
    {
      title: 'How much does a Public Display Licence cost?',
      content: `
      <p>The fees vary widely depending upon a range of criteria.</p>
      <p>Costs vary depending on the profile of the artist, the number of artworks, the location and method of display (whether the display is semi-public - such as a hotel room or an office lobby, or fully public - such as a screen facing the street or an artwork digitally mapped over a building) the audience, publicity and use of the work.</p>
      <p>Licences are given for a set duration of time, displays can be completely bespoke and crafted for any duration and/or viewing requirements.</p>
      <p>Museums and nonprofits receive preferential pricing.</p>
      `,
    },
  ] as {title: string, content: string}[];